@use "../config" as *;
@forward "../form/common";
.waf-component {
    &.waf-static-content {
        @extend %mb-0;
        @extend %px-1;
        @extend %pb-0;
    }
}
.text-uppercase {
    text-transform: uppercase
}
.waf-static-content {
    .waf-head {
        @extend %pb-3;
        .title {
            @extend %font-18-pb;
        }
    }
    .title {
        @extend %font-18-pb;
        @extend %uppercase;
        @extend %white;
        @extend %mb-0;
    }
    .sub-title {
        @extend %secondary-dark;
        @extend %my-0;
        @extend %pb-2;
        @extend %font-18-pb;
        @extend %capitalize;
    }
    .text,
    p,
    li {
        @extend %secondary-dark;
        @extend %pb-2;
        @extend %font-16-pr;
    }
    a {
        font-size: inherit;
        text-decoration: underline;
        word-break: break-all;
        @extend %primary;
    }
    strong,
    blockquote {
        font-size: 1.6rem;
    }
    ol,
    ul {
        li {
            &::marker {
                @extend %font-16-pb;
            }
        }
    }
    ol {
        list-style-type: decimal;
    }
    ul {
        list-style-type: disc;
    }
    .lower-roman {
        list-style-type: lower-roman;
    }
    .upper-roman {
        list-style-type: upper-roman;
    }
    .upper-alpha {
        list-style-type: upper-alpha;
    }
    .lower-alpha {
        list-style-type: lower-alpha;
    }
    .list-none {
        list-style-type: none;
    }
    .list-square {
        list-style-type: square;
    }
    .table-responsive {
        @extend %d-block;
        @extend %x-auto;
        @extend %w-100;
        .table {
            border-collapse: separate;
            @extend %w-100;
            @extend %mb-6;
            tr {
                @extend %flex;
                &:not(:last-child) {
                    @include border(10, light-grey, 1, bottom);
                }
                &:nth-child(even) {
                    @extend %transparent-bg;
                }
            }
            td,
            th {
                flex-shrink: 1;
                flex-basis: 100%;
                text-transform: unset;
                @extend %w-50;
                @extend %font-12-pr;
            }
            thead {
                @extend %secondary-dark-bg;
                th {
                    height: 4rem;
                    @extend %font-12-pm;
                    @extend %white;
                    @extend %py-0;
                    @extend %flex-n-c;
                }
            }
            tbody {
                border-spacing: 0 1rem;
                tr {
                    td {
                        word-wrap: break-word;
                        @extend %white-bg;
                        &:nth-child(odd) {
                            font-weight: 700;
                            @extend %secondary-light-bg-1;
                        }
                    }
                }
            }
        }
    }
    .static-thumbnail {
        @extend %mx-2-neg;
        + .static-body {
            @extend %mt-4-neg;
        }
    }
    .static-body {
        box-shadow: 0 0 0.4rem 0 hsl(var(--hsl-black) / 0.1);
        @extend %py-6;
        @extend %px-3;
        @extend %quarter-radius;
        @extend %mt-6;
        @extend %white-bg;
        @extend %relative;
    }
    &.waf-bullet-section {
        @extend %p-0;
        @extend %mb-6;
        .title {
            @extend %mb-5;
        }
        .description {
            @extend %white;
            @extend %pb-0;
        }
        .bullet {
            &-wrap {
                background: linear-gradient(92.14deg, hsl(var(--hsl-secondary-dark)) 1.03%, hsl(var(--hsl-primary)) 288.18%);
                @extend %quarter-radius;
                @extend %py-8;
                @extend %px-4;
                @extend %relative;
                @extend %hidden;
            }
            &-thumbnail {
                width: 16rem;
                height: 16rem;
                aspect-ratio: 1/1;
                flex-shrink: 0;
                @extend %mb-8;
                @extend %mx-auto;
            }
            &-item:not(:last-of-type) {
                @include border(1, white, 2, bottom);
                @extend %pb-8;
                @extend %mb-8;
            }
        }
        li {
            @extend %white;
            @extend %pb-0;
            @extend %mb-6;
            @extend %pl-2;
            &:last-of-type {
                @extend %mb-0;
            }
            &::marker {
                @include icon(football, 15);
                @extend %accent;
            }
        }
    }
    &.waf-broadcasters {
        p {
            line-height: 2;
        }
        .static-body {
            box-shadow: unset;
            @extend %transparent-bg;
            @extend %p-0;
        }
        .blue-section {
            background: linear-gradient(97.02deg, var(--secondary) -1.04%, var(--secondary-light) 52.04%, var(--secondary-dark) 103%);
            isolation: isolate;
            @extend %hidden;
            @extend %px-3;
            @extend %py-6;
            @extend %mb-3;
            @extend %relative;
            &::before {
                content: "";
                width: 10rem;
                height: 10rem;
                background-repeat: no-repeat;
                background-size: cover;
                filter: invert(1);
                opacity: 0.1;
                transform: rotate(28deg);
                z-index: var(--z-negative);
                @include position(-45%, -20%);
                @include bgImg("svg/hexagon.svg");
            }
            .sub-title,
            p {
                @extend %white;
            }
        }
    }
}
.waf-broadcasters {
    .table {
        .title,
        .text {
            text-transform: unset;
            line-height: 1.6;
            @extend %secondary;
            @extend %font-12;
        }
        .title,
        thead th {
            font-weight: 700;
        }
    }
    .table-responsive {
        .indian-broadcasters {
            :is(tr:not(:last-child)) {
                @include border(1, secondary, 3, bottom);
            }
            .title,
            thead th {
                font-weight: 700;
            }
        }
    }
    .static-thumbnail {
        margin-inline: var(--space-1-neg)
    }
}
.waf-contact-us {
    @extend %pb-0;
    .waf-body {
        @extend %white-bg;
        @extend %mx-3-neg;
    }
    .form-title {
        @extend %font-22-pb;
        @extend %mb-3;
    }
    .form-head {
        @extend %m-0;
    }
    .form-data {
        @extend %px-6;
        @extend %pt-4;
    }
    .form-list {
        background: hsl(var(--hsl-secondary-light));
        @extend %pb-6;
        .form-title {
            @extend %white;
        }
        &::before {
            content: unset;
        }
    }
    .form-wrapper {
        @extend %pb-0;
    }
    .form-info {
        &-item {
            @extend %mb-5;
            &:last-of-type {
                @extend %mb-0;
            }
        }
    }
    .item-label {
        @extend %font-14-pm;
        @extend %pb-0;
        @extend %mb-1;
        @extend %relative;
        &::before {
            @extend %d-block;
            @include icon(email, 23);
        }
    }
    .item-description {
        @extend %font-18-pb;
        @extend %pb-0;
    }
    textarea.form-control {
        min-height: 13.7rem;
        @extend %pt-2;
    }
    .btn-fill {
        @extend %w-100;
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-static-content {
        .waf-head {
            .title {
                font-size: 2.6rem;
            }
        }
        .static-thumbnail {
            margin-inline: 0;
            margin-top: var(--space-6);
            + .static-body {
                margin-top: 0;
            }
        }
        .static-body {
            padding-inline: var(--space-9);
        }
        &.waf-bullet-section {
            .bullet {
                &-wrap {
                    padding: var(--space-10);
                    &::after {
                        content: "";
                        width: 41.5rem;
                        height: 48rem;
                        background-repeat: no-repeat;
                        background-size: cover;
                        filter: invert(1);
                        opacity: 0.05;
                        transform: rotate(28deg);
                        @include position(-35%, -20%);
                        @include bgImg("svg/hexagon.svg");
                    }
                }
                &-item {
                    gap: var(--space-10);
                    @include flex-config(flex, row, flex-start, center);
                }
                &-thumbnail {
                    margin-bottom: 0;
                    width: 14.5rem;
                    height: 14.5rem;
                }
                &-content {
                    width: calc(100% - 19rem);
                }
            }
            li {
                margin-bottom: var(--space-4);
            }
        }
    }
    .waf-broadcasters {
        .title,
        .text {
            line-height: 1.4;
            font-size: 1.6rem;
        }
    }
    .waf-contact-us {
        .waf-body {
            margin-block: var(--space-6);
        }
        .form-list {
            max-height: unset;
        }
        .form-data {
            width: 35%;
            max-width: 41rem;
            flex-shrink: 0;
            @include flex-config(flex, column, center, flex-start);
        }
        .form-btn {
            text-align: center;
        }
        .btn-fill {
            width: 30%;
        }
    }
}
@media screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    .waf-contact-us {
        .form-data {
            width: 53%;
        }
    }
}